.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spacer {
  padding-top: 10px;
}

.page-wrapper {
  height: 100%;
}

.page-wrapper > .makeStyles-root-1,
.page-wrapper > .makeStyles-root-1 > .makeStyles-content-2 {
  height: 100%;
  background-color: white !important;
}

@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

.ag-header-cell,
.ag-cell,
.ag-header-cell .ag-header-cell-sortable,
.ag-header-cell .ag-floating-filter,
.ag-header-group-cell,
.ag-header-group-cell-with-group {
  text-align: left;
  display: block;
  height: 60px;
}

.ag-theme-alpine {
  color: #badbfe;
}

.ag-theme-alpine .ag-row {
  font-size: 12px !important;
}

.ag-theme-alpine .ag-header {
  background-color: #e7edf3 !important;
}

/* .ag-theme-alpine .ag-row-odd {
  background-color: #ffffff;
} */

.ag-header-row,
.ag-row,
.ag-header-row-column-filter {
  /* height: 45px !important; */
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  text-align: left;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: ' \2714';
}

.ag-cell-value {
  text-overflow: 'ellipsis' !important;
  white-space: 'nowrap' !important;
  display: block;
}

.outer-container {
  padding: 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  position: relative;
  padding-left: 30px;
}

.outer-container::after {
  content: ' ';
  position: absolute;
  width: 2px;
  background: #0055af;
  top: 10px;
  bottom: 10px;
  left: 15px;
}

/* Delete this component just used for reference */

/* added v2 starts here */

main {
  padding: 0px !important;
}

.filter_main_container {
  position: fixed;
  top: 0px;
  background: #fff;
  left: 0;
  right: 0;
  box-shadow: none;
  border-bottom: 1px solid #ebecf0;
  z-index: 9999;
}

.filter_heading_container {
  width: 100%;
  margin: 0px;
}

/* .header_container {
  padding: 0 22px;
} */

.filter_heading {
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1d;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  min-height: 66px;
}

.custom_filter_card_heading {
  display: none;
}

.filter_btn_container {
  margin: 15px 0;
  display: flex;
}

.filter_dd_main_container {
  border-top: 1px solid #ebecf0;
  padding-top: 15px;
}

.filter_form_component_container {
  position: relative;
  padding-left: 15px;
}

.filter_form_component_container::before {
  background: #0055af;
  width: 2px;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: -5px;
  content: ' ';
}

.filter_sub_heading {
  font-size: 16px;
  font-weight: 500;
  color: #1d1d1d;
  padding: 0px;
  padding-bottom: 10px;
  margin: 0px;
}

.btn_bottom_container_filter {
  padding-top: 15px;
  border-top: 1px solid #ebecf0;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  width: 100%;
}

.primary_btn {
  margin-right: 15px;
}

.filter_dd_main_container::after {
  background: rgba(29, 29, 29, 0.7);
  height: 100vh;
  width: 100vw;
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
}

.impact_grid_sub_container {
  padding: 0 25px;
}

.filter_main_container .MuiAccordionSummary-contentGutters {
  margin: 0px !important;
  padding: 0 24px;
}

.filter_main_container .makeStyles-headerCard-10 {
  border-radius: 0px;
  box-shadow: none;
}

.filter_main_container .MuiPaper-rounded {
  border-radius: 0px;
}

.filter_main_container .MuiAccordionDetails-root {
  padding-bottom: 0px;
  padding-top: 0px;
}

.secondary_btn button {
  background: #fff;
  border: 1px solid #0055af;
  color: #0055af;
}

.accordion_main_container {
  padding: 10px 15px;
  border: 1px solid #c8ced7;
  border-radius: 3px;
  box-sizing: border-box;
}

.accordion_main_container .MuiAccordion-gutters {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.css-b4bb07-MuiGrid-root {
  flex-basis: 25%;
  max-width: 25%;
}

.css-1lj5egr-MuiGrid-root {
  flex-basis: 25%;
  max-width: 25%;
}

.card_container .MuiCard-root {
  box-shadow: none;
  border: 1px solid #c8ced7;
}

.card_bottom_container .MuiPaper-root {
  box-shadow: none;
  border: 1px solid #c8ced7;
  border-radius: 3px;
}

.card_container .MuiGrid-container {
  margin-left: 0px !important;
}

.card_container .MuiGrid-root {
  padding-left: 0px;
}

.card_container .MuiGrid-item {
  max-width: 45%;
}

/*added v2 ends here */

.filter_icon_button {
  margin-right: 10px;
  cursor: pointer;
  color: #757575;
}

/*ADA exclusive*/
.filter_dd_main_container_0 {
  border-top: 1px solid #ebecf0;
  padding-top: 15px;
}
.filter_dd_main_container_parent {
  max-height: 75vh;
  overflow-y: auto;
}
.overlay {
  background: rgba(29, 29, 29, 0.7);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
}

.filter_form_component_container_0 {
  position: relative;
  padding-left: 15px;
}

.filter_form_component_container_0::before {
  width: 2px;
  position: absolute;
  left: 0;
  top: 0px;
  bottom: -5px;
  content: ' ';
}
/**/
.link-style{
  color:#3065b5;
  font-weight: 600;
  cursor: pointer;
}
.link-style:hover{
  color:#103863;
}


