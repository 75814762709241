.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}

.horizontal-divider {
  margin: 30px 0px;
  height: 1px;
  background-color: #ccc;
}

.filter-list-container {
  margin-bottom: 10px;
  padding: 10px 20px;
  border: 1px solid #ccc;
}

.filter-list-item {
  margin: 4px 0px;
}

.filter-list-item-title {
  margin-right: 10px;
  color: #666;
  font-size: 12px;
}

.filter-list-item-value {
  margin-right: 10px;
  padding: 8px 20px;
  font-size: 12px;
  background-color: #eeeeee;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.filter-list-item-close {
  margin-left: 10px;
  color: #999;
  font-size: 16px;
  cursor: pointer;
}

.create-profile-title {
  margin-bottom: 10px;
  color: #666;
  font-size: 12px;
}
